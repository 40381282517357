.flex {
  display: flex;
}
.grow {
  flex-grow: 1;
}
.grow-2 {
  flex-grow: 2;
}
.grow-3 {
  flex-grow: 3;
}
.grow-4 {
  flex-grow: 4;
}
.grow-5 {
  flex-grow: 5;
}

.gap {
  gap: 1.25rem;
}

.gap-1 {
  gap: 1.5rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 2.5rem;
}

.gap-4 {
  gap: 3rem;
}
.gap-5 {
  gap: 5rem;
}

.nogrow {
  flex-grow: 0;
}
.wrap {
  flex-wrap: wrap;
}
.wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flexline {
  display: inline-flex;
}

.center {
  justify-content: center;
  align-items: center;
}

.start {
  justify-content: flex-start;
}
.end {
  justify-content: flex-end;
}
.justify {
  justify-content: center;
}
.evenly {
  justify-content: space-evenly;
}
.between {
  justify-content: space-between;
}
.around {
  justify-content: space-around;
}

.left {
  align-items: flex-start;
}
.right {
  align-items: flex-end;
}
.middle {
  align-items: center;
}
.fullHeight {
  align-items: stretch;
}

.column {
  flex-direction: column;
}
.column-rev {
  flex-direction: column-reverse;
}

.center-middle {
  align-self: flex-end;
}

.min-h-85 {
  min-height: 85vh;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
