.appbar {
  display: flex;
  flex: 1;

  padding: 0px 16px;
  padding-right: 32px;
  min-height: 70px;
  align-items: center;

  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

@media (max-width: 962px) {
  .appbar {
    padding: 0px 16px;
  }
}

.drawer > div {
  border: none !important;
}

.list .MuiListItemButton-root {
  border-radius: 24px;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.list ul > div .MuiListItemButton-root {
  margin-left: 20px;
  width: 230px !important;
}

.list .active {
  background-color: rgba(0, 174, 239, 0.15);
}
.list .active > svg {
  color: var(--color-primary);
}
.list .active > div > .MuiTypography-root {
  color: var(--color-primary);
  font-weight: bold;
  text-shadow: 1px;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.page {
  margin-top: -65px;
  padding-top: 65px;
}