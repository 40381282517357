.line {
    background-color: #e9e9e9;
    border-radius: 4px;
    border-bottom: 0.5px solid #b3aaaa;
    box-sizing: border-box;
}

.filled {
    width: 100%;
    height: 100%;
}

.filled-item {
    width: 100%;
    cursor: pointer;
    border-radius: 2px;
    transition: .2s background ease-in-out;
}

.filled-item:hover {
    background-color: #d5d5d5;
}

.title {
    width: 100%;
}

.option {
    display: inline-block;
    border-radius: 5px;
    padding: 3px 10px;
    margin: 5px 5px 0px 0px !important;
    margin-left: 0px;
    color: white;
    cursor: pointer;
    background-color: var(--color-primary-alt);
    transition: 0.2s background ease-in-out;
}

.option:hover {
    background-color: var(--color-primary);
}

.noline .filled-item{
    cursor:initial !important;
}
.noline .filled-item:hover {
    background-color: white !important;
}