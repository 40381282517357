

/* SPINNER */
.SpinnerGround {
  position: fixed;
  background-color: rgba(0,0,0, .4);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000 !important;
}

.SpinnerAlt {
  background-color: rgba(0,0,0, .12) !important;
}

.SpinnerBody {
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
}

.skChase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.skChaseDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.skChaseDot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.skChaseDot:nth-child(1) { animation-delay: -1.1s; }
.skChaseDot:nth-child(2) { animation-delay: -1.0s; }
.skChaseDot:nth-child(3) { animation-delay: -0.9s; }
.skChaseDot:nth-child(4) { animation-delay: -0.8s; }
.skChaseDot:nth-child(5) { animation-delay: -0.7s; }
.skChaseDot:nth-child(6) { animation-delay: -0.6s; }
.skChaseDot:nth-child(1):before { animation-delay: -1.1s; }
.skChaseDot:nth-child(2):before { animation-delay: -1.0s; }
.skChaseDot:nth-child(3):before { animation-delay: -0.9s; }
.skChaseDot:nth-child(4):before { animation-delay: -0.8s; }
.skChaseDot:nth-child(5):before { animation-delay: -0.7s; }
.skChaseDot:nth-child(6):before { animation-delay: -0.6s; }

@keyframes sk-chase {
  100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot {
  80%, 100% { transform: rotate(360deg); }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}
