.modal-form {
  min-height: 300px;
}

.modal-error {
  display: block;
  margin: auto;
  color: var(--color-secondary-alt);
  font-weight: bold;
  text-align: center;
}

.modal-adjust {
  max-height: 500px !important;
}
.modal-buttons, .end-buttons {
  position: fixed;
  z-index: 800;
  border-radius: 4px;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
  outline: none;
  padding: 10px 20px;
  width: 100%;
  background-color: white;
  max-width: 620px;
}

.cuestionario-end-buttons {
  z-index: 800;
  position: fixed;
  border-radius: 4px;
  outline: none;
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%);
  padding: 10px 20px;
  width: 90%;
  max-width: 940px;
  border-top: 1px solid #cecece;
}

.modal-buttons > div {
  border-top: 1px solid #d9d9d9;
  padding-top: 10px;
  justify-content: flex-end;
}

.modal-footer, .modal-footer .btn-center {
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-end;
}

.modal-footer > div {
  border-top: 1px solid #d9d9d9;
  padding-top: 10px;
  margin-top: 10px;
}

.modal-action-close {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.modal-action-close svg {
  color: var(--color-secondary-alt) !important;
  transition: color .3s ease-in-out !important;
}

.modal-action-close svg:hover {
  color: var(--color-secondary) !important;
  cursor: pointer;
}

.modal-small-icon {
  margin-right: .35rem;
  font-size: .85rem !important;
}