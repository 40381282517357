._list {
  background-color: white;
  display: flex;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid #d9d9d9;
}

._avatar {
  display: inline-flex;
}

._body {
  display: inline-flex;
  flex-grow: 1;
  flex-direction: column;
  padding-left: 16px;
}
