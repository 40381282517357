

/* Sombras */
.MuiDialog-paper {width: 450px !important;}
.MuiImageListItemBar-root {background-color: rgba(0,0,0,.25) !important;}
.MuiImageListItem-item img:hover {cursor: pointer;}
.MuiPopover-paper {
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0px 1px 5px -3px rgba(0,0,0,0.032),0px 3px 8px 1px rgba(0,0,0,0.0314),0px 3px 10px 2px rgba(0,0,0,0.0312) !important;
  min-width: 130px !important;
}



.MuiTablePagination-toolbar {
  border-top: 1px solid var(--color-shadow);
}

.MuiTablePagination-spacer {
  display: none;
}
.MuiMenu-paper {
  max-height: 650px !important;
}
.MuiPickersMonthSelection-container {
  width: 100% !important;
}
.MuiPickersBasePicker-pickerView {
  max-width: 650px !important;
}

.no-disabled-material input {
  color: black !important;
}

.menu-item-selected {
  background-color: var(--color-primary) !important;
  color: white !important;
}
.menu-item-selected svg {
  color: white !important;
}

.hide-dropdown-button .MuiSelect-icon {
  display: none;
}

.hide-dropdown-button .MuiInputAdornment-root {
  margin-right: 0px;
}