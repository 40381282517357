.floatButton button {
  position: fixed !important;
  bottom: 15px;
  right: 10px;
  z-index: 1000 !important;
  background-color: var(--color-primary);
}

.floatButtonText {
  position: fixed !important;
  bottom: 15px;
  left: 10px;
  z-index: 1000 !important;
}

.floatButtonAlt button {
  bottom: 65px;
  right: 35px;
  width: 36px !important;
  height: 36px !important;
  background-color: var(--color-secondary-alt);
}
