.headerSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: auto;
  margin: auto;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .headerSection {
    margin-top: 20px;
  }
}

.headerTitle {
  display: flex;
  flex-grow: 2;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.headerAppButton {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.headerActive {
  background-color: #f5f5f5 !important;
  color: #acacac;
}

.headerAppItems .MuiListItem-button { transition:  all .3s ease-in-out; }
.headerAppItems .MuiListItem-button:hover {
  background-color: var(--color-primary-alt) !important;
  color: white;
}

.headerSection .headerLabel {
  padding-right: 50px;
  padding-left: 5px;
}
.headerLabel {
  cursor: pointer;
}

@media (max-width: 600px) {
  .headerLabel {
    display: none;
  }
}
