.text-primary {
  color: var(--color-primary) !important;
}
.text-secondary {
  color: var(--color-primary-alt) !important;
}
.text-alternative {
  color: var(--color-secondary) !important;
}
.text-danger {
  color: var(--color-danger) !important;
}
.text-warning {
  color: var(--color-warning) !important;
}
.text-success {
  color: var(--color-success) !important;
}
.text-muted {
  color: var(--color-shadow) !important;
}
.text-ground {
  color: var(--color-ground) !important;
}
.text-gray {
  color: var(--color-secondary-alt) !important;
}
.text-white {
  color: white !important;
}

.bg-primary {
  background-color: var(--color-primary) !important;
}
.bg-secondary {
  background-color: var(--color-primary-alt) !important;
}
.bg-alternative {
  background-color: var(--color-secondary) !important;
}
.bg-gray {
  background-color: var(--color-secondary-alt) !important;
}
.bg-danger {
  background-color: var(--color-danger) !important;
}
.bg-warning {
  background-color: var(--color-warning) !important;
}
.bg-success {
  background-color: var(--color-success) !important;
}
.bg-muted {
  background-color: var(--color-shadow) !important;
}
.bg-white {
  background-color: white !important;
}
.bg-none {
  background: transparent !important;
}

.badge-black span {
  background-color: black;
  transform: scale(1) translate(0%, 20%) !important;
}

/* Luz de color */
.light {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  margin: auto;
  filter: brightness(180%);
}

.light-block {
  display: inline-block;
}
.greenlight {
  background-color: green;
  box-shadow: 0px 0px 3px 0.5px green;
}
.graylight {
  filter: brightness(150%) !important;
  background-color: var(--color-ground);
  box-shadow: 0px 0px 3px 0.5px var(--color-ground);
}
.redlight {
  background-color: var(--color-secondary-alt);
  box-shadow: 0px 0px 3px 0.5px var(--color-secondary-alt);
}

.bluelight {
  background-color: var(--color-primary);
  box-shadow: 0px 0px 3px 0.5px var(--color-primary);
}
.graylight {
  background-color: var(--color-ground);
  box-shadow: 0px 0px 3px 0.5px var(--color-ground);
}
