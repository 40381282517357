.main, .mainsmall {
  position: fixed;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 0px;
  background-color: var(--color-primary);
  transition: all 0.3s ease-in-out;
  z-index: 1300;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
}

.agenda {
  position: sticky;
  top: 60px;
  background-color: white;
  box-shadow: none;
  border-bottom: 1px solid #f3f3f3;
}

.mainsmall {
  height: 60px;
}

.menu {
  z-index: 1352 !important;
}
