.menu {
  position: fixed;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  max-width: 300px;
  padding: 5px 0px;
  z-index: 100 !important;
}

.menu-subtitle {
  height: 30px !important;
  position: relative !important;
  display: flex !important;
  align-items: center !important;
}

.text-bold {
  font-weight: bold;
}