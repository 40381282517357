@media (max-width: 425px) {
  .hide-xs {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .hide-sm {
    display: none !important;
  }
}

/* Small Size */
.font-size-small .MuiTypography-h3 {
  font-size: calc(3rem - 1.5px);
}
.font-size-small .MuiTypography-h4 {
  font-size: calc(2.125rem - 1.5px);
}
.font-size-small .MuiTypography-h5 {
  font-size: calc(1.5rem - 1.5px);
}
.font-size-small .MuiTypography-h6 {
  font-size: calc(1.25rem - 1.5px);
}
.font-size-small .MuiTypography-body1,
.font-size-small .MuiTypography-subtitle1,
.font-size-small .MuiFormLabel-root {
  font-size: calc(1rem - 1.5px);
}
.font-size-small .MuiTab-wrapper,
.font-size-small .MuiTypography-body2,
.font-size-small .MuiTypography-subtitle2,
.font-size-small .MuiTypography-button {
  font-size: calc(0.875rem - 1.5px);
}
.font-size-small .MuiTypography-caption,
.font-size-small .MuiTypography-overline {
  font-size: calc(0.7rem - 1.5px);
}
.font-size-small .MuiButton-root {
  font-size: calc(0.875rem - 1.5px);
}
.font-size-small .MuiButton-containedSizeSmall {
  font-size: calc(0.8125rem - 1.5px);
}

/* Normal Size */
.MuiTypography-h3 {
  font-size: 3rem;
}
.MuiTypography-h4 {
  font-size: 2.125rem;
}
.MuiTypography-h5 {
  font-size: 1.5rem;
}
.MuiTypography-h6 {
  font-size: 1.25rem;
}
.MuiTypography-body1,
.MuiTypography-subtitle1,
.MuiFormLabel-root {
  font-size: 1rem;
}
.MuiTab-wrapper,
.MuiTypography-body2,
.MuiTypography-subtitle2,
.MuiTypography-button {
  font-size: 0.875rem;
}
.MuiTypography-caption,
.MuiTypography-overline {
  font-size: 0.65rem;
}
.MuiButton-root {
  font-size: 0.875rem;
}
.MuiButton-containedSizeSmall {
  font-size: 0.8125rem;
}
.MuiListItemText-primary .MuiTypography-body1 {
  font-size: 0.875rem;
}

/* Big Size */
.font-size-big .MuiTypography-h3 {
  font-size: calc(3rem + 1.5px);
}
.font-size-big .MuiTypography-h4 {
  font-size: calc(2.125rem + 1.5px);
}
.font-size-big .MuiTypography-h5 {
  font-size: calc(1.5rem + 1.5px);
}
.font-size-big .MuiTypography-h6 {
  font-size: calc(1.25rem + 1.5px);
}
.font-size-big .MuiTypography-body1,
.font-size-big .MuiTypography-subtitle1,
.font-size-big .MuiFormLabel-root {
  font-size: calc(1rem + 1.5px);
}
.font-size-big .MuiTab-wrapper,
.font-size-big .MuiTypography-body2,
.font-size-big .MuiTypography-subtitle2,
.font-size-big .MuiTypography-button {
  font-size: calc(0.875rem + 1.5px);
}
.font-size-big .MuiTypography-caption,
.font-size-big.MuiTypography-overline {
  font-size: calc(0.65rem + 1.5px);
}
.font-size-big.MuiButton-root {
  font-size: calc(0.875rem + 1.5px);
}
.font-size-big.MuiButton-containedSizeSmall {
  font-size: calc(0.8125rem + 1.5px);
}

/* Extra Size */
.font-size-extra .MuiTypography-h3 {
  font-size: calc(3rem + 3px);
}
.font-size-extra .MuiTypography-h4 {
  font-size: calc(2.125rem + 3px);
}
.font-size-extra .MuiTypography-h5 {
  font-size: calc(1.5rem + 3px);
}
.font-size-extra .MuiTypography-h6 {
  font-size: calc(1.25rem + 3px);
}
.font-size-extra .MuiTypography-body1,
.font-size-extra .MuiTypography-subtitle1,
.font-size-extra .MuiFormLabel-root {
  font-size: calc(1rem + 3px);
}
.font-size-extra .MuiTab-wrapper,
.font-size-extra .MuiTypography-body2,
.font-size-extra .MuiTypography-subtitle2,
.font-size-extra .MuiTypography-button {
  font-size: calc(0.875rem + 3px);
}
.font-size-extra .MuiTypography-caption,
.font-size-extra .MuiTypography-overline {
  font-size: calc(0.65rem + 3px);
}
.font-size-extra .MuiButton-root {
  font-size: calc(0.875rem + 3px);
}
.font-size-extra .MuiButton-containedSizeSmall {
  font-size: calc(0.8125rem + 3px);
}
