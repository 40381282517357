html {
  scroll-behavior: smooth;
}
div {
  outline: none !important;
}
p {
  margin-top: 0px;
}

#root,
html,
body {
  width: 100%;
  height: 100vh;
  min-width: 320px !important;
  min-height: 500px;
}

.min-width-25 {
  min-width: 25px;
}
.min-width-50 {
  min-width: 50px;
}
.min-width-75 {
  min-width: 75px;
}
.min-width-100 {
  min-width: 100px;
}
.min-width-125 {
  min-width: 125px;
}
.min-width-150 {
  min-width: 150px;
}
.min-width-175 {
  min-width: 175px;
}
.min-width-200 {
  min-width: 200px;
}
.min-width-225 {
  min-width: 225px;
}
.min-width-250 {
  min-width: 250px;
}
.min-width-275 {
  min-width: 275px;
}
.min-width-300 {
  min-width: 300px;
}

.min-height-250 {
  min-height: 250px;
}

.min-height-25 {
  min-height: 25px;
}

.min-height-50 {
  min-height: 50px;
}

.min-height-75 {
  min-height: 75px;
}

.min-height-100 {
  min-height: 100px;
}

.min-height-125 {
  min-height: 125px;
}

.min-height-150 {
  min-height: 150px;
}

.min-height-175 {
  min-height: 175px;
}

.min-height-200 {
  min-height: 200px;
}

.min-height-225 {
  min-height: 225px;
}

.min-height-250 {
  min-height: 250px;
}

.sticky-header {
  position: sticky;
  top: 5px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: auto;
  margin: auto;
  margin-top: 40px;
  background-color: #ededed;
}

/* Scroll */
::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
::-webkit-scrollbar-thumb {
  background: #b4b4b4 !important;
  border-radius: 50px !important;
}
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3 !important;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2) !important;
}
::-webkit-scrollbar-thumb:active {
  background-color: #999999 !important;
}
::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 10px !important;
}
::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track:active {
  background: #d4d4d4 !important;
}

.my {
  margin: 1.5rem 0 !important;
}

.mb {
  margin-bottom: 1.5rem !important;
}

.mt {
  margin-top: 1.5rem !important;
}

.mx {
  margin: 0 1.5rem !important;
}

.mr {
  margin-right: 1.5rem !important;
}

.ml {
  margin-left: 1.5rem !important;
}

.mr-m {
  margin-right: 0.5rem !important;
}

.ml-m {
  margin-left: 0.5rem !important;
}

.mx-m {
  margin: 0 0.5rem !important;
}

.mt-m {
  margin-top: 0.75rem !important;
}

.my-m {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.mb-m {
  margin-bottom: 0.75rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.py {
  padding: 1.5rem 0 !important;
}

.px {
  padding: 0 1.5rem !important;
}

.pb {
  padding-bottom: 1.5rem !important;
}

.pt {
  padding-top: 1.5rem !important;
}

.pr {
  padding-right: 1.5rem !important;
}

.pl {
  padding-left: 1.5rem !important;
}

.pt-m {
  padding-top: 0.75rem !important;
}

.py-m {
  padding: 0.75rem 0 !important;
}

.pb-m {
  padding-bottom: 0.75rem !important;
}

.pr-m {
  padding-right: 0.5rem !important;
}

.pl-m {
  padding-left: 0.5rem !important;
}

.px-m {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.p-m {
  padding: 0.5rem !important;
}

.p {
  padding: 1.5rem !important;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.pm-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}