:root {
  --color-primary: #014990;
  --color-primary-alt: #00aeef;
  --color-secondary: #56004e;
  --color-secondary-alt: #a1a1a1;
  --color-ground: #7e8083;
  --color-shadow: #e1e1e1;
  --color-danger: #db1c2c;
  --color-warning: #ffd200;
  --color-success: #009688;
}

.app-overflow {
  margin-top: -65px;
  padding-top: 65px;
}

.app-x-overflow {
  overflow-x: hidden;
}

.blank {
  opacity: 0;
}

.none {
  display: none;
}

.borderBottom-0 {
  border-bottom: none !important;
}

.rem {
  font-size: 1rem !important;
}

.radio {
  border-radius: 6px;
}

.radio-50 {
  border-radius: 50% !important;
}

.error-section {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--color-danger) !important;
  font-size: 16px !important;
}

.request-error {
  margin-top: 15px;
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  color: var(--color-secondary-alt);
  text-align: center;
}
.request-align {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 960px;
  margin-top: 20px !important;
  cursor: pointer;
}

.link {
  text-decoration: none;
  color: var(--color-primary);
  font-size: 90%;
}

.btn-center {
  display: flex;
  justify-content: center;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px !important;
}

img {
  text-indent: -10000px;
  position: relative;
  background-color: #d8d8d8;
}

.label-center {
  width: 100%;
  color: var(--color-ground);
  text-align: center;
}

.label-error {
  width: 100%;
  text-align: center;
  color: var(--color-secondary-alt);
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

/* Picker */
.circle-picker {
  max-width: 100% !important;
}
.circle-picker span:nth-child(1) div span div {
  border: 1px solid #ccc;
}
.hue-picker-content {
  width: 100%;
}
.char-no-context {
  height: 430px;
}

.color-picker svg {
  color: #b1b1b1;
}
.re-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
}

.relative {
  position: relative;
}

.re-loading svg {
  color: #00aeef !important;
}

.lineborder {
  position: absolute;
  border-radius: 4px;
}
.opacity-1 {
  opacity: 1;
}
.opacity-0 {
  opacity: 0;
}
.lineborder-hover {
  opacity: 0;
}
.lineground:hover .lineborder-hover {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.lineborder-top {
  top: 0px;
  width: 100%;
  min-height: 6px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.lineborder-bottom {
  bottom: 0px;
  width: 100%;
  min-height: 6px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.lineborder-left {
  left: 0px;
  width: 6px;
  height: 100%;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.pointer {
  cursor: pointer !important;
}
.rounded {
  border-radius: 6px;
}
.container {
  width: 100%;
  margin: auto;
}
.hidden {
  overflow: hidden;
}

.connect-vertical {
  position: absolute;
  width: 4px;
  height: 100%;
  bottom: 0%;
  background-color: #7e8083;
  z-index: 100;
  left: 6px;
}
.connect-horizontal {
  position: absolute;
  width: 14px;
  height: 4px;
  background-color: #7e8083;
  left: 6px;
  top: 50%;
  z-index: 100;
  transform: translateY(50%);
}
.connect-hidden {
  position: absolute;
  width: 8px;
  height: calc(50% - 6px);
  left: 4px;
  bottom: 0px;
  z-index: 110;
  background-color: #ededed;
}


.connect-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  bottom: calc(0% + 0.75rem);
  background-color: #7e8083;
  z-index: 100;
  left: -18px;
  margin-left: 15px;
  border-radius: 50%;
}
.connect-step {
  position: absolute;
  width: 4px;
  height: calc(100% + 0.75rem);
  bottom: 0%;
  background-color:#7e8083;
  z-index: 100;
  left: 5px;
  margin-left: 15px;
}
.connect-line {
  position: absolute;
  height: 4px;
  width: 15px;
  bottom: calc(50% - 2px);
  background-color: #7e8083;
  z-index: 100;
  left: -15px;
  margin-left: 15px;
}

.connect-step-light {
  width: 2px;
  height: calc(100% + 0.75rem);
  bottom: 0%;
  background-color: #7e8083;
  z-index: 100;
  left: -15px;
}

.connect-line-light {
  height: 2px;
  width: 8px;
  bottom: calc(50% - 2px);
  background-color: #7e8083;
  z-index: 100;
  left: -15px;
}

.connect-break {
  position: absolute;
  width: 4px;
  height: calc(50% - 2px);
  bottom: 0%;
  background-color: #ededed;
  z-index: 200;
  left: -15px;
  margin-left: 15px;
}
.connect-break-white {
  background-color: white;
  left: -15.5px;
  height: calc(50% - 2px);
}

.input-fixed-pointer, .input-fixed-pointer input {
  cursor: pointer !important;
}

.input-fixed-bottom-line input {
  height: 31px !important;
}

.picker-icon {
  height: 150px !important;
  width: 150px !important;
  border: 1.5px solid white;
  padding: 10px;
}

.picker-icon:hover {
  cursor: pointer;
  border-color: rgba(0, 0, 0, 0.62);
}

.picker-icon-disable-csv {
  -webkit-filter: grayscale(1); /* Webkit */
  filter: gray; /* IE6-9 */
  filter: grayscale(1); /* W3C */
}
.picker-icon-disable-pdf {
  -webkit-filter: grayscale(1); /* Webkit */
  filter: gray; /* IE6-9 */
  filter: grayscale(1); /* W3C */
}
.picker-icon-disable-pdf:hover,
.picker-icon-disable-csv:hover {
  cursor: default !important;
  border-color: white !important;
}

.fa-primary {
  color: #989898;
}

.fa-secondary {
  color: black;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.chip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: 30px;
  padding: 1px 6px;
  transition: all .25s ease-in-out;
  cursor: pointer;
}

.chip:hover {
  background-color: var(--color-primary);
  color: white;
}

.chip-title{
  margin: 0px 1.5rem !important;
}

@media (max-width: 960px) {
  .chip-title {
    margin: 0px 0.25rem !important;
    font-size: 90% !important;
  }
}
.paciente-list {
  border: 0.5px solid transparent;
}

.paciente-list-active {
  background-color: #01499020;
  border: 0.5px solid #01499099;
}

.paciente-list:hover {
  cursor: pointer;
  background-color: #01499010;
}

.box-file-input {
  background-color: #f9f9f9;
  border: 3px dotted #e5e5e5;
  font-size: 20px;
}
.hide-file-input {
  display: none;
}

.resizable-image {
  cursor: pointer;
  border: 1.5px dotted #d2d2d2;
}

.resizable-image:hover {
  background-color:  #f5f5f5aa !important;
  transition: background .5s ease-in-out;
}

.chip-letter {
  margin: 5px 2.5px;
  box-shadow: 1px 1px 1px 0.5px #ccc;
  border-radius: 18px;
}
.chip-letter .MuiChip-root:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 0.25rem #dfdcdc);
}
.chip-letter .MuiChip-root {
  border-radius: 18px;
}
.chip-letter .MuiChip-label {
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
}

.chip-black .MuiChip-label {
  color: black;
}

.historia-inner-html {
  width: auto;
  max-height: 220px;
  overflow: scroll;
}